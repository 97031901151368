import { render, staticRenderFns } from "./CreateProductPage.vue?vue&type=template&id=31a7d647&scoped=true&"
import script from "./CreateProductPage.vue?vue&type=script&lang=js&"
export * from "./CreateProductPage.vue?vue&type=script&lang=js&"
import style0 from "./CreateProductPage.vue?vue&type=style&index=0&id=31a7d647&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a7d647",
  null
  
)

export default component.exports