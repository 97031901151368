<template>
  <div class="create-product-page">
    <BackTitle confirm-click @click="goBack">Create product</BackTitle>
    <ProductForm
      v-if="product"
      :value="product"
      :is-submitting="isSubmitting"
      submit-button-text="Create product"
      @submit="save"
    />
    <div class="create-product-page__fixed" data-v-step="24" />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import ProductForm from "@/components/products/ProductForm";
import { mapActions, mapState } from "vuex";
import { NEW_PRODUCT_MOCK } from "@/helpers/mocks";

export default {
  name: "CreateProductPage",
  components: { ProductForm, BackTitle },
  data() {
    return {
      isSubmitting: false,
      product: {
        ...NEW_PRODUCT_MOCK,
      },
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
    }),
  },
  methods: {
    ...mapActions({
      fetchProductById: "products/fetchProductById",
      createProduct: "products/createProduct",
    }),
    async save(data) {
      try {
        this.isSubmitting = true;
        await this.createProduct(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Products",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-product-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  &__fixed {
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
}
</style>
